<div class="twist-logo">
  @if (variant === "full") {
    <svg xmlns="http://www.w3.org/2000/svg" class="img-fluid"
         [attr.height]="height"
         [attr.width]="width"
         [style.height]="height"
         [style.max-height]="maxHeight"
         [style.width]="width"
         [style.max-width]="maxWidth"
         x="0px" y="0px" viewBox="0 0 100 58">
    <g>
      <path fill="#6FDBAA" d="M60.3,39.8l-1.2,5.4v0c-1.9,8.6,0,12.1,6.2,12.1c6.1,0,10-3.5,11.9-12.1l0.1-0.6c1.8-7.8,0.1-12.9-1.5-17.7
        c-1.4-4.3-2.8-8.4-1.5-14l0.6-2.8c0.3-1.4,0.8-1.9,1.6-1.9c0.7,0,1,0.5,0.7,1.9l-1.5,8.1h7.6l1.2-5.4c1.9-8.6,0-12.1-6.2-12.1
        c-6.1,0-10,3.5-11.9,12.1l-0.1,0.6c-1.8,7.8-0.1,12.9,1.5,17.7c1.4,4.3,2.8,8.4,1.5,14L68.7,48c-0.3,1.4-0.8,1.9-1.6,1.9
        c-0.7,0-1-0.5-0.7-1.9l1.5-8.1H60.3z"/>
      <path fill="#6FDBAA" d="M79.1,42.8H83l8.3-34.4c0.2-0.6,0-0.8-0.6-0.8h-3.9L88.4,1h6.1c3.9,0,5.1,1.4,4.2,5.3l-8.8,36.4h3.9
        l-1.6,6.5h-3.9L86.5,57h-6.9l1.9-7.7h-3.9L79.1,42.8L79.1,42.8z"/>
      <path fill="#6FDBAA" d="M18.9,15.2H15L8.5,49.6c-0.1,0.6,0.1,0.8,0.7,0.8h4.2L12.1,57H5.7c-3.9,0-5.2-1.4-4.5-5.3l6.9-36.5H4.1
        L5,8.7h4.2L10.7,1h6.9l-1.5,7.7h3.9L18.9,15.2z"/>
      <path fill="#6FDBAA" d="M23.5,1.3l-8.8,55.4h12.6l7.3-32.8H36l-5.1,32.8h12.6L56.1,1.3h-7.6l-8.4,42.5h-1.7l6.2-42.5h-9.7
        l-9.8,42.5h-1.8L31,1.3H23.5z"/>
      <path fill="#6FDBAA" d="M46.5,56.7l12-55.4h7.9l-12,55.4H46.5z"/>
    </g>
    </svg>
  }
  @if (variant === "compact") {
    <svg xmlns="http://www.w3.org/2000/svg" class="img-fluid"
         [attr.height]="height"
         [attr.width]="width"
         [style.height]="height"
         [style.max-height]="maxHeight"
         [style.width]="width"
         [style.max-width]="maxWidth"
         x="0px" y="0px" viewBox="0 0 45 100" overflow="visible" xml:space="preserve">
      <path fill-rule="evenodd" fill="#6FDBAA" d="M14.5,86.8L14.5,86.8l5.8-0.1L18,99l10.4-0.4l2.4-11.9l5.9-0.2l2-10.1l-5.9,0.2L44,20.3
        c1.2-6-0.7-8.1-6.6-7.9l-9.3,0.3l0,0h-4.6L25.8,1H15.4l-2.2,11.8H6.8l-1.4,10h5.9L0.9,78.7c-1.1,6,0.8,8.1,6.7,8.1H14.5z M27.2,22.8
        h0.5l0,0l4.4-0.2c1,0,1.2,0.3,1,1.3L22.3,76.7L19,76.8h-6c-1,0-1.2-0.3-1-1.3l9.8-52.8h4.4l0,0H27.2z"/>
    </svg>
  }
</div>
