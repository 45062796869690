import { inject } from '@angular/core';
import { NgbActiveOffcanvas } from '@ng-bootstrap/ng-bootstrap';

export abstract class OffCanvasComponent<TResult> {

  protected offCanvasRef = inject(NgbActiveOffcanvas);

  dismissOffCanvas(reason?: any) {
    this.offCanvasRef.dismiss(reason);
  }

  closeOffCanvas(result: TResult){
    this.offCanvasRef.close(result);
  }

}
