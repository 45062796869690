import { Component, input } from '@angular/core';

@Component({
    selector: 'ui-page-header',
    host: { 'style': 'display: contents' },
    templateUrl: './page-header.component.html',
    standalone: true
})
export class PageHeaderComponent {

  pageTitle = input.required<string>();

}
