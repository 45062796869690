import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[uiScrollIntoViewWhenSelected]',
  standalone: true
})
export class ScrollIntoViewWhenSelectedDirective {

  constructor(private _elementRef: ElementRef) {
  }

  @Input()
  set selected(value: boolean) {
    if (value){
      this._elementRef.nativeElement.scrollIntoView(true);
      setTimeout(() => {
        this._elementRef.nativeElement.scrollIntoView(true);
      }, 200);
    }
  }
}
