import { Component, input } from '@angular/core';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';

@Component({
    selector: 'ui-submit-button',
    templateUrl: './submit-button.component.html',
    styleUrl: './submit-button.component.scss',
    standalone: true,
    imports: [FaIconComponent]
})
export class SubmitButtonComponent {

  submitPending = input(false);

  disabled = input(false);

  label = input('[no label]');

  protected readonly faSpinner = faSpinner;
}
