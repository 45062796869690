<button
  [disabled]="submitPending() || disabled()"
  type="submit"
  class="btn btn-block btn-primary btn-block">
  @if (submitPending()) {
    <fa-icon [icon]="faSpinner" animation="spin" [fixedWidth]="true" size="lg"></fa-icon>
  } @else {
    <span>{{ label() }}</span>
  }
</button>
