<div class="avatar"
     [class.avatar-initials]="renderInitials()"
     [style.--user-color]="stringToColor(username() ??'')"
     [style.color]="'var(--user-color)'">
  @if (!renderInitials()) {
    <img
      [src]="avatarUrl()"
      (error)="fallbackToInitials()"
      class="img-fluid rounded-circle"
      alt="{{username()}}"/>
  } @else {
    {{ initials() }}
  }
</div>
