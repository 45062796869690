import { Component } from '@angular/core';

@Component({
    selector: 'ui-button',
    templateUrl: './button.component.html',
    styleUrl: './button.component.scss',
    standalone: true,
})
export class ButtonComponent {



}
