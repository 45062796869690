import { Component, input } from '@angular/core';
import { LoadingComponent } from '../loading/loading.component';

@Component({
  host: { 'style': 'display: contents' },
  selector: 'ui-page',
  templateUrl: './page.component.html',
  standalone: true,
  imports: [
    LoadingComponent
  ]
})
export class PageComponent {

  isLoading = input<boolean>(false)

}
