import { Component, ContentChild, forwardRef, input, signal, TemplateRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { NgIf, NgClass, NgTemplateOutlet } from '@angular/common';
import { ControlValueAccessorDirective } from '../../directives/control-value-accessor.directive';
type InputType = 'text' | 'number' | 'email' | 'password' | 'tel';
export const PASSWORD_FIELD_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => PasswordFieldComponent),
  multi: true,
};

@Component({
    selector: 'ui-password-field',
    templateUrl: './password-field.component.html',
    styleUrl: './password-field.component.scss',
    providers: [PASSWORD_FIELD_CONTROL_VALUE_ACCESSOR],
    standalone: true,
    imports: [NgIf, FormsModule, ReactiveFormsModule, NgClass, NgTemplateOutlet, FaIconComponent]
})
export class PasswordFieldComponent<T> extends ControlValueAccessorDirective<T>{

  @ContentChild('validationMessages', {static: true}) validationMessages?: TemplateRef<any>;

  $showPasswordFields = signal(false)

  name = input.required<string>();

  showValidationErrors = input(false);

  label = input('[no label]');

  placeholder= input('');

  floatingLabel = input(false);

  size = input<'sm' | 'md' | 'lg'>('md')

  toggleShowPassword() {
    this.$showPasswordFields.set(!this.$showPasswordFields())
  }

  protected readonly faEyeSlash = faEyeSlash;
  protected readonly faEye = faEye;
}
