import { Component, Input } from '@angular/core';

@Component({
    selector: 'ui-twist-logo',
    templateUrl: './twist-logo.component.html',
    standalone: true,
})
export class TwistLogoComponent {

  @Input()
  variant: 'full' | 'compact' = "full"

  @Input()
  color: 'green' | 'inherit' = 'green'


  @Input()
  height: string | undefined;

  @Input()
  width: string | undefined;

  @Input()
  maxHeight: string | undefined;

  @Input()
  maxWidth: string | undefined;

}
