import { Component, ContentChild, forwardRef, input, TemplateRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgIf, NgClass, NgTemplateOutlet } from '@angular/common';
import { ControlValueAccessorDirective } from '../../directives/control-value-accessor.directive';
type InputType = 'text' | 'number' | 'email' | 'password' | 'tel';
export const INPUT_FIELD_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InputFieldComponent),
  multi: true,
};

@Component({
    selector: 'ui-input-field',
    templateUrl: './input-field.component.html',
    styleUrl: './input-field.component.scss',
    providers: [INPUT_FIELD_CONTROL_VALUE_ACCESSOR],
    standalone: true,
    imports: [NgIf, FormsModule, ReactiveFormsModule, NgClass, NgTemplateOutlet]
})
export class InputFieldComponent<T> extends ControlValueAccessorDirective<T>{

  @ContentChild('validationMessages', {static: true}) validationMessages?: TemplateRef<any>;

  type = input<InputType>('text');

  name = input.required<string>();

  showValidationErrors = input(false);

  label = input('[no label]');

  placeholder= input('');

  floatingLabel = input(false);

  size = input<'sm' | 'md' | 'lg'>('md')
}
