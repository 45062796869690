<ul class="wizard-steps">
  @for (step of steps(); track step.index) {
    <li
      [class.active]="step.active"
      [class.completed]="step.completed"
      [attr.disabled]="step.disabled">
      @if (step.children.length > 0) {
        <details open>
          <summary>{{ step.label }}</summary>
          <ul>
            <li>
              <details disabled>
                <summary>Gas giants</summary>
                <ul>
                  <li>Jupiter</li>
                  <li>Saturn</li>
                </ul>
              </details>
            </li>
            <li>
              <details>
                <summary>Ice giants</summary>
                <ul>
                  <li>Uranus</li>
                  <li>Neptune</li>
                </ul>
              </details>
            </li>
          </ul>
        </details>
      } @else {
        <span>{{ step.label }}</span>
      }
    </li>
  }
</ul>
