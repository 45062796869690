<div class="form-field" *ngIf="control">
  @if (floatingLabel()) {
    <div class="input-group mb-3">
      <div class="form-floating">
        <input
          [type]="$showPasswordFields() ? 'text' : 'password'"
          [name]="name()"
          [id]="name()"
          class="form-control"
          [required]="isRequired"
          [formControl]="control"
          [ngClass]="{'is-invalid': control.invalid && (( control.dirty && control.touched) || showValidationErrors())}"
          [placeholder]="placeholder() || label()" />
        <label [for]="name()" i18n>{{ label() }}<span class="ms-1" *ngIf="isRequired">*</span></label>
        <div class="validation-tooltip"
             *ngIf="validationMessages && (control.invalid && (( control.dirty && control.touched) || showValidationErrors()))">
          <ng-container *ngTemplateOutlet="validationMessages"></ng-container>
        </div>
      </div>
      <span class="input-group-text" (click)="toggleShowPassword()">
              <fa-icon *ngIf="!$showPasswordFields()" [icon]="faEyeSlash" [fixedWidth]="true"></fa-icon>
              <fa-icon *ngIf="$showPasswordFields()" [icon]="faEye" [fixedWidth]="true"></fa-icon>
           </span>

    </div>
  } @else {
    <label class="form-label" [for]="name()" i18n>{{ label() }}<span class="ms-1" *ngIf="isRequired">*</span></label>
    <div class="input-group mb-3">
      <input
        [type]="$showPasswordFields() ? 'text' : 'password'"
        [name]="name()"
        [id]="name()"
        class="form-control"
        [required]="isRequired"
        [formControl]="control"
        [ngClass]="{'is-invalid': control.invalid && (( control.dirty && control.touched) || showValidationErrors())}"
        [placeholder]="placeholder() || label()" />

      <span class="input-group-text" (click)="toggleShowPassword()">
          <fa-icon *ngIf="!$showPasswordFields()" [icon]="faEyeSlash" [fixedWidth]="true"></fa-icon>
          <fa-icon *ngIf="$showPasswordFields()" [icon]="faEye" [fixedWidth]="true"></fa-icon>
       </span>

      <div class="validation-tooltip"
           *ngIf="validationMessages && (control.invalid && (( control.dirty && control.touched) || showValidationErrors()))">
        <ng-container *ngTemplateOutlet="validationMessages"></ng-container>
      </div>
    </div>
  }
</div>
