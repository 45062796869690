import { inject, Injectable, Injector, Type } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from './modal.component';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  #modalService = inject(NgbModal);

  open<TComponent extends ModalComponent<TResult>, TResult>(componentType: Type<TComponent>, options?: ModalOptions): ModalReference<TComponent, TResult> {

    const size = options?.size == 'large' ? 'lg' : options?.size == 'small' ? 'sm' : 'md';

    const ref = this.#modalService.open(componentType, {
      size: size,
      injector: options?.injector,
      backdrop: true,
      fullscreen: options?.size == 'fullscreen',
    });
    return {
      component: ref.componentInstance as TComponent,
      result: new Promise<TResult>((resolve, reject) => {
        ref.result.then(res => {
          resolve(res as TResult);
        }).catch(err => {
          reject(err);
        });
      })
    } as ModalReference<TComponent, TResult>;
  }
}

export type ModalOptions = {
  injector: Injector,
  size?: 'small' | 'medium' | 'large' | 'fullscreen';
}

export type ModalReference<TComponent extends ModalComponent<TResult>, TResult> = {
  component: TComponent;
  result: Promise<TResult>;
}
