<div class="form-field">
  @if (floatingLabel()) {
    <div class="form-floating mb-3" *ngIf="control">
      <input
        [type]="type()"
        [name]="name()"
        [id]="name()"
        class="form-control"
        [required]="isRequired"
        [formControl]="control"
        [ngClass]="{'is-invalid': control.invalid && (( control.dirty && control.touched) || showValidationErrors())}"
        [placeholder]="placeholder() || label()" />
      <label [for]="name()" i18n>{{ label() }}<span class="ms-1" *ngIf="isRequired">*</span></label>
      <div class="validation-tooltip"
           *ngIf="validationMessages && (control.invalid && (( control.dirty && control.touched) || showValidationErrors()))">
        <ng-container *ngTemplateOutlet="validationMessages"></ng-container>
      </div>
    </div>
  } @else {
    <div class="mb-3" *ngIf="control">
      <label class="form-label" [for]="name()" i18n>{{ label() }}<span class="ms-1" *ngIf="isRequired">*</span></label>
      <input
        [type]="type()"
        [name]="name()"
        [id]="name()"
        class="form-control"
        [required]="isRequired"
        [formControl]="control"
        [ngClass]="{'is-invalid': control.invalid && (( control.dirty && control.touched) || showValidationErrors())}"
        [placeholder]="placeholder() || label()" />
      <div class="validation-tooltip"
           *ngIf="validationMessages && (control.invalid && (( control.dirty && control.touched) || showValidationErrors()))">
        <ng-container *ngTemplateOutlet="validationMessages"></ng-container>
      </div>
    </div>
  }
</div>
