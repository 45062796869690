import { Component, computed, effect, input, signal } from '@angular/core';
import { NgOptimizedImage } from '@angular/common';

@Component({
    selector: 'ui-avatar',
    templateUrl: './avatar.component.html',
    styleUrl: './avatar.component.scss',
    standalone: true,
    imports: [NgOptimizedImage]
})
export class AvatarComponent {

  email = input<string | undefined>(undefined);

  username = input<string | undefined>(undefined);

  emailHash = signal<string | undefined>(undefined);

  renderInitials = signal<boolean>(false);

  avatarUrl = computed(() => {
    const emailHash = this.emailHash();
    // using Gravatar => https://docs.gravatar.com/general/images/
    return emailHash ? `https://www.gravatar.com/avatar/${emailHash}?s=80&d=404` : undefined;
  });

  initials = computed(() => {
    const username = this.username();
    return username ? this.getInitials(username) : undefined;
  });

  emailHashEffect = effect(() => {
    const email = this.email();
    if (email) {
      this.getSha256Hash(email)
        .then((sha256Hash) => {
          this.emailHash.set(sha256Hash);
        });
    }
  }, { allowSignalWrites: true });

  getSha256Hash = async (email: string): Promise<string> => {
    // encode as UTF-8
    const msgBuffer = new TextEncoder().encode(email);
    // hash the message
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);
    // convert ArrayBuffer to Array
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    // convert bytes to hex string
    return hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
  };

  stringToColor = (string: string, saturation = 100, lightness = 75) => {
    let hash = 0;
    for (let i = 0; i < string.length; i++) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
      hash = hash & hash;
    }
    return `hsl(${(hash % 360)}, ${saturation}%, ${lightness}%)`;
  };

  getInitials = (username: string): string => {
    const allNames = username.trim().split(' ');
    return allNames.reduce((acc, curr, index) => {
      if (index === 0 || index === allNames.length - 1) {
        acc = `${acc}${curr.charAt(0).toUpperCase()}`;
      }
      return acc;
    }, '');
  };

  fallbackToInitials() {
    this.renderInitials.set(true);
  }
}

