import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';
import { WizardStep } from './wizard-steps-tree.models';

@Component({
  selector: 'ui-wizard-steps-tree',
  standalone: true,
  imports: [CommonModule],
  styleUrl: './wizard-steps-tree.component.scss',
  templateUrl: './wizard-steps-tree.component.html'
})
export class WizardStepsTreeComponent {
  public steps = input.required<WizardStep[]>();
}
