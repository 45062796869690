import { Component } from '@angular/core';

@Component({
    host: { 'style': 'display: contents' },
    selector: 'ui-page-content',
    templateUrl: './page-content.component.html',
    standalone: true
})
export class PageContentComponent {
}
